import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import AnimatedNumber from '../components/AnimatedNumber/AnimatedNumber';
import ContactForm from '../components/ContactForm/ContactForm';
import LogoCarousel from '../components/LogoCarousel/LogoCarousel';
import { Seo } from '../components/Seo';
import Alert from '../components/UI/Alert/Alert';
import { OutboundLink } from '../components/UI/OutboundLink/OutboundLink';
import productivityGraph from '../images/home/aida-productivity-graph.svg';
import productivityIcon from '../images/home/aida-productivity.svg';
import roiMoney from '../images/home/aida-roi-money.svg';
import roiIcon from '../images/home/aida-roi.svg';
import timeSavedIcon from '../images/home/aida-time-saved-clock.svg';
import timeSavedHourglass from '../images/home/aida-time-saved-hourglass.svg';
import Layout from '../layouts/layout';
import { registerButtonClickConversion, registerScrollDepthConversion } from '../shared/utils';
import * as classes from './landing1.module.scss';
import '../styles/tailwind.scss';

const POSITIONS = {
    HERO: 'hero',
    NA: 'na',
    FEEDBACK: 'feedback',
    STATS: 'stats',
    CUSTOMERS: 'customers'
};

const LandingPage1 = props => {
    const { t } = useI18next();
    const [ slide1, setSlide1 ] = useState(0);
    const contactFormRef = useRef(null);
    const [ success, setSuccess ] = useState(null);
    const heroRef = useRef(null);
    const featuresRef = useRef(null);
    const awardsRef = useRef(null);
    const statsRef = useRef(null);
    const feedbackRef = useRef(null);
    const customersRef = useRef(null);

    useEffect(() => {
        const observerCallback = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const sectionId = entry.target.dataset.section;
                    // Values represent importance (higher = more valuable)
                    switch (sectionId) {
                        case 'hero':
                            registerScrollDepthConversion(0.1); // Basic page view
                            break;
                        case 'features':
                            registerScrollDepthConversion(0.3); // Interest in features
                            break;
                        case 'awards':
                            registerScrollDepthConversion(0.5); // Social proof engagement
                            break;
                        case 'stats':
                            registerScrollDepthConversion(0.8); // High intent (ROI section)
                            break;
                        case 'feedback':
                            registerScrollDepthConversion(0.7); // Testimonial engagement
                            break;
                        case 'customers':
                            registerScrollDepthConversion(0.5); // Brand trust engagement
                            break;
                        case 'contact-form':
                            registerScrollDepthConversion(1); // Highest intent
                            break;
                    }

                    // Unobserve after conversion is tracked once
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, {
            threshold: 0.4 // Element is 40% visible before triggering
        });

        // Observe all sections
        if (heroRef.current) observer.observe(heroRef.current);
        if (featuresRef.current) observer.observe(featuresRef.current);
        if (awardsRef.current) observer.observe(awardsRef.current);
        if (statsRef.current) observer.observe(statsRef.current);
        if (feedbackRef.current) observer.observe(feedbackRef.current);
        if (customersRef.current) observer.observe(customersRef.current);
        if (contactFormRef.current) observer.observe(contactFormRef.current);

        return () => {
            // Clean up
            observer.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSection1Slide = (index, iconComponent, contentComponent) => {
        return <div className={classes.Slide}>
            <div className={classes.Icon}>{iconComponent}</div>
            <div className={classes.Title}>{t(`landing1-page:section1.slide.${index}.title`)}</div>
            <div className={classes.Content}>{contentComponent}</div>
        </div>;
    };

    const handleScrollToContactForm = (event, source) => {
        event.preventDefault();
        contactFormRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

        registerButtonClickConversion(2.5);

        posthog.capture('landing_page_primary_action', {
            source
        });
    };

    const getFeedback = (title, contentComponent, author, role) => {
        return <div className="flex flex-col items-center col-lg-4">
            <div className={classes.Stars}>
                <FontAwesomeIcon icon="star" />
                <FontAwesomeIcon icon="star" className="ml-2" />
                <FontAwesomeIcon icon="star" className="ml-2" />
                <FontAwesomeIcon icon="star" className="ml-2" />
                <FontAwesomeIcon icon="star" className="ml-2" />
            </div>
            <div className={clsx(classes.Title, 'text-xl text-primary mt-2')}>{title}</div>
            <div className={clsx(classes.Description, 'text-base text-gray5 mt-1 text-italic')}>{contentComponent}</div>
            <div className="text-gray5 text-bold mt-4">{author}</div>
            <div className="text-sm text-gray4 mb-8">{role}</div>
        </div>;
    };

    const handleSuccess = (name, email) => {
        setSuccess({ name, email });
    };

    const callToActionButtons = (containerClasses = '', position = POSITIONS.NA) => {
        const handleSecondaryClick = () => {
            posthog.capture('landing_page_secondary_action', {
                source: position
            });

            registerButtonClickConversion(1.5);
        };

        return <div className={`flex justify-center ${containerClasses}`}>
            <a href="#"
               onClick={e => handleScrollToContactForm(e, position)}
               className={`btn btn-primary ${classes.CtaButton}`}
            >
                        <span className="d-none d-md-block">
                            <FontAwesomeIcon icon="comments" />&nbsp;
                            {t('common:contactUsLong')}
                        </span>
                <span className="d-block d-md-none">
                            <FontAwesomeIcon icon="comments" />&nbsp;
                    {t('common:contactUs')}
                        </span>
            </a>
            <Link to="/" className={`btn btn-secondary ml-2 ${classes.CtaButton}`} onClick={handleSecondaryClick}>
                <Trans>Explore the features</Trans>
            </Link>
        </div>;
    };

    const name = success?.name;
    const email = success?.email;

    return <Layout location={props.location} showNewsletter={false} showCta={false} showHeader={false}
                   showFooter={false}>
        <div className={classes.Landing}>
            <div ref={heroRef} data-section="hero" className={clsx(classes.Hero)}>
                <div className="container p-0">
                    <div className="flex flex-col items-center">
                        <Link className={`pt-5 pb-4 ${classes.Logo}`} to="/">
                            <StaticImage
                                src="../images/aida-logo-white.png"
                                alt="AIDA logo"
                                width={192}
                                height={79}
                                placeholder="none"
                                loading="eager"
                            />
                        </Link>
                        <h1 className={classes.Title}>Artificial Intelligence for Document Automation</h1>
                        <h2 className={classes.Subtitle}>{t('landing1-page:hero.subtitle')}</h2>
                        <div className={classes.Tiles}>
                            <StaticImage
                                src="../images/landing/aida-landing1-hero.png"
                                alt="AIDA robot"
                                width={500}
                                placeholder="none"
                                loading="lazy"
                                className="z-index-10"
                            />
                            <div className={clsx(classes.Tile, classes.Tile1)}>
                                <StaticImage
                                    src="../images/landing/tile-white.png"
                                    alt="AIDA robot tile"
                                    width={180}
                                    placeholder="none"
                                    loading="lazy"

                                />
                            </div>
                            <div className={clsx(classes.Tile, classes.Tile2)}>
                                <StaticImage
                                    src="../images/landing/tile-white.png"
                                    alt="AIDA robot tile"
                                    width={180}
                                    placeholder="none"
                                    loading="lazy"
                                />
                            </div>
                            <div className={clsx(classes.Tile, classes.Tile3)}>
                                <StaticImage
                                    src="../images/landing/tile-gray.png"
                                    alt="AIDA robot tile"
                                    width={180}
                                    placeholder="none"
                                    loading="lazy"
                                />
                            </div>
                            <div className={clsx(classes.Tile, classes.Tile4)}>
                                <StaticImage
                                    src="../images/landing/tile-white.png"
                                    alt="AIDA robot tile"
                                    width={180}
                                    placeholder="none"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                        <a href="#"
                           onClick={e => handleScrollToContactForm(e, POSITIONS.HERO)}
                           className={clsx('mt-3', classes.Button)}
                        >
                            <span>
                                <FontAwesomeIcon icon="comments" />&nbsp;
                                {t('common:contactUsLong')}
                            </span>
                        </a>
                        <Trans i18nKey="landing1-page:hero.description">
                            <div className="text-sm text-white text-center mb-3 mt-3">The Starter plan is free forever,
                                                                                      with
                                                                                      no strings
                                                                                      attached.<br /> AIDA grows with
                                                                                      your
                                                                                      business: choose
                                                                                      flexible plans with unlimited
                                                                                      storage
                                                                                      and users, with no
                                                                                      setup costs.
                            </div>
                        </Trans>
                    </div>
                </div>
            </div>
            <div ref={featuresRef} data-section="features" className={clsx('container p-0', classes.Section1)}>
                <h2 className="pt-6">{t('landing1-page:section1.title')}</h2>
                <h3>{t('landing1-page:section1.subtitle')}</h3>
                <div className={classes.Description}>
                    <Trans i18nKey="landing1-page:section1.content">
                        <p>AIDA redefines document automation with an intelligent, Hybrid-AI engine that
                           combines <strong>adaptive
                                            AI</strong> with <strong>no-code
                                                                     configuration</strong>,
                           making it more versatile than typical generative AI solutions.</p>
                        <p>Here’s why organizations are choosing AIDA for a more
                           flexible, <strong>human-like</strong> approach
                           to document processing:</p>
                    </Trans>
                </div>
                <div className="position-relative">
                    <div className={classes.Icons}>
                        <div className={classes.Left} onClick={() => {
                            setSlide1(slide1 <= 0 ? 4 : slide1 - 1);
                        }}>
                            <FontAwesomeIcon icon={[ 'fal', 'angle-left' ]} />
                        </div>
                        <div className={classes.Right} onClick={() => {
                            setSlide1(slide1 >= 4 ? 0 : slide1 + 1);
                        }}>
                            <FontAwesomeIcon icon={[ 'fal', 'angle-right' ]} />
                        </div>
                    </div>
                    {slide1 === 0 && getSection1Slide(0, <StaticImage
                            src="../images/landing/icons/aida-smart-document-management.png"
                            alt={t('landing1-page:section1.slide.0.title')}
                            width={200}
                            placeholder="none"
                            loading="eager"
                        />,
                        <Trans i18nKey="landing1-page:section1.slide.0.content">
                            <p>Unlike generative AI engines that may perform well on static, basic documents but can’t
                               be
                               trained or corrected, <strong>AIDA is designed to adapt</strong>.
                               Initial training requires only one example document, and <strong>AIDA continues to
                                                                                                learn</strong> from any
                               changes with the same single-click logic.</p>
                            <p>As your document layouts evolve, <strong>AIDA instantly adjusts</strong>—no lengthy
                               reconfiguration or large data sets needed. This flexibility means that AIDA grows with
                               your
                               business, delivering continuous, accurate results as your document needs change.</p>
                        </Trans>)}
                    {slide1 === 1 && getSection1Slide(1, <StaticImage
                            src="../images/landing/icons/aida-hybrid-ai.png"
                            alt={t('landing1-page:section1.slide.1.title')}
                            width={200}
                            placeholder="none"
                            loading="eager"
                        />,
                        <Trans i18nKey="landing1-page:section1.slide.1.content">
                            <p>While many generative AI solutions are limited in the types of documents they can
                               process, AIDA’s Hybrid-AI handles over 16,000 document variations with ease,
                               from invoices and contracts to more complex regulatory forms.</p>
                            <p>Its unique architecture enables it to <strong>follow document
                                                                             changes</strong> intuitively, eliminating
                               the need for constant oversight and reducing the risk of errors in data extraction.</p>
                        </Trans>)}
                    {slide1 === 2 && getSection1Slide(2, <StaticImage
                            src="../images/landing/icons/aida-relations-payments.png"
                            alt={t('landing1-page:section1.slide.2.title')}
                            width={200}
                            placeholder="none"
                            loading="eager"
                        />,
                        <Trans i18nKey="landing1-page:section1.slide.2.content">
                            <p>Simplify document workflows with AIDA’s advanced tools. Effortlessly link, organize, and
                               retrieve documents through automated workflows integrated with Google Drive, Microsoft,
                               SAP, and more.</p>
                            <p>AIDA <strong>dynamically connects related files</strong>, ensuring easy access and a
                               clear overview of operations. From archiving to processing, AIDA boosts efficiency while
                               reducing complexity.</p>
                        </Trans>)}
                    {slide1 === 3 && getSection1Slide(3, <StaticImage
                            src="../images/landing/icons/aida-save-time-roi.png"
                            alt={t('landing1-page:section1.slide.3.title')}
                            width={200}
                            placeholder="none"
                            loading="eager"
                        />,
                        <Trans i18nKey="landing1-page:section1.slide.3.content">
                            <p>With its fast implementation and ongoing adaptability, AIDA delivers ROI from day
                               one.</p>
                            <p>No large-scale setups, no need for constant updates—<strong>AIDA continually
                                                                                           learns</strong> with each new
                               document, providing a long-term, low-maintenance solution for document processing.</p>
                        </Trans>)}
                    {slide1 === 4 && getSection1Slide(4, <StaticImage
                            src="../images/landing/icons/aida-gpt-charts.png"
                            alt={t('landing1-page:section1.slide.4.title')}
                            width={200}
                            placeholder="none"
                            loading="eager"
                        />,
                        <Trans i18nKey="landing1-page:section1.slide.4.content">
                            <p>AIDA brings all these advanced capabilities to your organization at <strong>one of the
                                                                                                           lowest costs
                                                                                                           in the
                                                                                                           market</strong>—without
                               cutting corners.</p>
                            <p>With AIDA, you get a powerful, adaptable solution that saves time, reduces errors, and
                               meets the demands of real-world document processing,
                               all at a price point designed for high accessibility and affordability.</p>
                        </Trans>)}
                </div>
                {callToActionButtons('mt-4', POSITIONS.FEATURES)}
                <div className="text-sm text-gray4 mt-3 mb-4">{t('landing1-page:section1.claim')}</div>
            </div>
            <div ref={awardsRef} data-section="awards" className={classes.Awards}>
                <div className={clsx('container p-0')}>
                    <h2 className="pt-6">{t('landing1-page:awards.title')}</h2>
                    <div className="flex mt-6">
                        <div className="items-center row">
                            <div className="col-md-3 flex justify-center my-6">
                                <StaticImage
                                    src="../images/awards/aida-deep-analysis-2023-innovation-award.png"
                                    alt="Deep Analysis 2023 Innovation Award"
                                    className="mr-6"
                                />
                            </div>
                            <div className="col-md-9 flex">
                                <div className="items-start text-gray3 d-none d-sm-flex d-md-none d-lg-flex">
                                    <FontAwesomeIcon icon="quote-left" size="3x" />
                                </div>
                                <div className="text-lg text-gray4 ml-6">
                                    <Trans i18nKey="index-page:award.1">
                                        <p><strong>TCLAB AIDA</strong> document capture product contains innovative
                                                                       features
                                                                       that set it apart, including its hybrid approach
                                                                       combining AI with no-code templating.</p>
                                        <p>We particularly liked its pragmatic, hybrid approach to
                                           capture and automation that does not rely solely on AI
                                           but combines the best of both worlds: templating
                                           (albeit simple, no code) and AI.</p>
                                        <p>The result is a system that can be deployed quickly and can handle a wide
                                           array
                                           of
                                           document types with minimal effort. </p>
                                    </Trans>
                                    <div className="mt-3 text-gray5 text-xl text-right">Deep Analysis</div>
                                </div>
                                <div className="items-end text-gray3 ml-6 pb-9 d-none d-sm-flex d-md-none d-lg-flex">
                                    <FontAwesomeIcon icon="quote-right" size="3x" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {callToActionButtons('mt-6', POSITIONS.AWARDS)}
                    <div className="text-sm text-gray4 mt-3 pb-4 text-center">{t('landing1-page:awards.claim')}</div>
                </div>
            </div>
            <div ref={statsRef} data-section="stats" className={classes.StatsContainer}>
                <div className={clsx(classes.Stats, 'container p-0')}>
                    <h2 className="pt-6">
                        {t('index-page:statsTitle')}
                    </h2>
                    <h3>
                        {t('index-page:statsSubtitle')}
                    </h3>
                    <div className="row gx-0 gx-lg-5">
                        <div className="col-lg-4">
                            <div className={classes.Detail}>
                                <div className={classes.Image}>
                                    <img src={timeSavedHourglass} alt="AIDA saves time (image)" width="100%" />
                                    <div className={classes.Icon}>
                                        <img src={timeSavedIcon} alt="AIDA saves time (icon)" width={60} height={60} />
                                    </div>
                                </div>
                                <div className={clsx(classes.Statistic, 'text-gradient')}>
                                    <AnimatedNumber id="counter-time" targetNumber={96} className="text-gradient"
                                                    suffix="%" />
                                </div>
                                <h2 className={classes.Title}><Trans>Time saved</Trans></h2>
                                <div className={classes.Description}>{t('index-page:stat2')}</div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className={classes.Detail}>
                                <div className={classes.Image}>
                                    <img src={productivityGraph} alt="AIDA increases productivity (image)"
                                         width="100%" />
                                    <div className={classes.Icon}>
                                        <img src={productivityIcon} alt="AIDA increases productivity (icon)" width={60}
                                             height={60} />
                                    </div>
                                </div>
                                <div className={clsx(classes.Statistic, 'text-gradient')}>
                                    <AnimatedNumber id="counter-boost" targetNumber={21} className="text-gradient"
                                                    suffix="x" />
                                </div>
                                <h2 className={classes.Title}><Trans>Productivity boost</Trans></h2>
                                <div className={classes.Description}>{t('index-page:stat1')}</div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className={clsx(classes.Detail, classes.Last)}>
                                <div className={classes.Image}>
                                    <img src={roiMoney} alt="AIDA returns its investment (image)" width="100%" />
                                    <div className={classes.Icon}>
                                        <img src={roiIcon} alt="AIDA returns its investment (icon)" width={60}
                                             height={60} />
                                    </div>
                                </div>
                                <div className={clsx(classes.Statistic, 'text-gradient')}>
                                    <AnimatedNumber id="counter-roi" targetNumber={1500} className="text-gradient"
                                                    suffix="%" />
                                </div>
                                <h2 className={classes.Title}><Trans>ROI</Trans></h2>
                                <div className={classes.Description}>
                                    <Trans i18nKey="index-page:stat3">
                                        <div>Obtaining high Return on Investment is natural with AIDA: this is the
                                             average
                                             that its users have reached after 3 months of use, with guidance from
                                             our <Link
                                                to="/support/contacts">Onboarding team</Link>.
                                        </div>
                                    </Trans>
                                </div>
                            </div>
                        </div>
                    </div>
                    {callToActionButtons('mt-8', POSITIONS.STATS)}
                    <div className="text-sm text-gray4 mt-3 pb-4 text-center">{t('landing1-page:stats.claim')}</div>
                </div>
            </div>
            <div ref={feedbackRef} data-section="feedback" className={classes.Feedback}>
                <div className={clsx('container p-0')}>
                    <h2 className="pt-6">{t('landing1-page:feedback.title')}</h2>
                    <h3>{t('landing1-page:feedback.subtitle')}</h3>
                    <div className="row">
                        {getFeedback(
                            t('landing1-page:feedback.1.title'),
                            <Trans i18nKey="landing1-page:feedback.1.content">
                                <p>The ease of use and the <strong>ability to create many rules</strong> is definitely
                                   what impressed me about AIDA.
                                   I had tried other software, but with AIDA, I just need to create one type of document
                                   and it does everything else.</p>
                                <p>Excellent support, ease of use, and a very competitive cost.</p>
                            </Trans>,
                            'Andrea',
                            'Competence Centre (Italy)'
                        )}
                        {getFeedback(
                            t('landing1-page:feedback.2.title'),
                            <Trans i18nKey="landing1-page:feedback.2.content">
                                <p>A great solution with functionality that works to drive digital transformation.</p>
                                <p><strong>Speed and functionality</strong> is a key driver for this product.</p>
                            </Trans>,
                            'Martyn',
                            'Application Sales EM (United Kingdom)'
                        )}
                        {getFeedback(
                            t('landing1-page:feedback.3.title'),
                            <Trans i18nKey="landing1-page:feedback.3.content">
                                <p>The best way to <strong>retrieve and organize personal and business
                                                           information</strong> with just one click. Fantastic!</p>
                                <p>It doesn't seem real that it can do all these things so quickly. Previously, these
                                   tasks took much longer and had risks of errors.</p>
                            </Trans>,
                            'Sergio',
                            'Sales Manager (Italy)'
                        )}
                    </div>
                    {callToActionButtons('', POSITIONS.FEEDBACK)}
                    <div className="text-sm text-gray4 mt-3 pb-4 text-center">
                        <Trans i18nKey="landing1-page:feedback.claim">
                            <span>Read all of our customer reviews on our pages on
                                       <OutboundLink href="https://www.capterra.com/p/197178/AIDA/reviews/"
                                                     target="_blank"
                                                     rel="noopener">Capterra</OutboundLink> and
                                <OutboundLink
                                    href="https://www.g2.com/products/technology-cognition-lab-s-r-l-aida/reviews"
                                    target="_blank" rel="noopener">G2</OutboundLink></span>
                        </Trans>
                    </div>
                </div>
            </div>
            <div ref={customersRef} data-section="customers" className={classes.CustomersContainer}>
                <h2 className="pt-6">{t('index-page:Customers')}</h2>
                <h3><Trans>that grow with us</Trans></h3>
                <div className="my-10">
                    <LogoCarousel />
                </div>
                {callToActionButtons('mt-8', POSITIONS.CUSTOMERS)}
                <div className="text-sm text-gray4 mt-3 pb-4 text-center">{t('landing1-page:customers.claim')}</div>
            </div>
            <div ref={contactFormRef} data-section="contact-form" className={classes.ContactContainer}>
                <div className={clsx('container p-0')}>
                    <h2 className="pt-6">{t('landing1-page:contact.title')}</h2>
                    <h3>{t('landing1-page:contact.subtitle')}</h3>
                    {!success && <div className="row mt-6">
                        <div
                            className={clsx(classes.Description, 'col-md-5 text-primary text-lg pr-10 position-relative')}>
                            <Trans i18nKey="landing1-page:contact.content">
                                <p>Fill out the form below and one of our experts will show you how AIDA can transform
                                   document management in your company.</p>
                                <p>Receive a free demonstration!</p>
                            </Trans>
                            <div className={classes.Image}>
                                <StaticImage src="../images/landing/aida-robot-contact.png" alt="AIDA Robot" />
                            </div>
                        </div>
                        <div className="col-md-7 bg-gray1 z-index-2 mb-6">
                            <ContactForm isLandingPage={true} onSuccess={handleSuccess} />
                        </div>
                    </div>}
                    {success && <div className="flex flex-col items-center">
                        <div className={classes.Success}>
                            <Alert type="Success" title={t('common:contacts.form.success.title', { name: name })}>
                                <Trans i18nKey="common:contacts.form.success.message" email={email}>
                                    <p>Your request will be reviewed shortly by a person from our team.</p>
                                    <p>Check your email {{ email }} and look for an email from support@aidacloud.com; if
                                       you
                                       don't see it
                                       within a
                                       few minutes, check your "junk mail" or "spam" folder.</p>
                                    <p>We will do our best to respond to your inquiry as soon as possible.</p>
                                </Trans>
                            </Alert>
                            <div className="flex justify-center"><Link to="/" className="btn btn-primary btn-wide mt-4"
                                                                       style={{ maxWidth: '300px' }}>
                                <Trans>Continue your AIDA journey</Trans>&nbsp;
                                <FontAwesomeIcon icon="arrow-right" />
                            </Link></div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    </Layout>;
};

export default LandingPage1;

export const query = graphql`
query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "landing1-page", "index-page", "contacts-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'landing1-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'AIDA - Artificial Intelligence for Document Automation';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};


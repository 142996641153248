import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Slider from 'react-slick';

const LogoCarousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        autoplay: true,
        speed: 4000,
        autoplaySpeed: 4000,
        cssEase: 'linear',
        pauseOnHover: false,
        centerMode: true,
        responsive: [
            {
                breakpoint: 2000,
                settings: {
                    slidesToShow: 6
                }
            },
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1550,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '60px'
                }
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '60px'
                }
            }
        ]
    };

    return <Slider {...settings}>
        <div>
            <StaticImage src="../../images/customers/aida-customer-jacobacci.png" height={80}
                         alt="Jacobacci & Partners" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-dierre.png" height={80} alt="Dierre porte" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-sauter.png" height={80} alt="Sauter" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-lascala.png" height={80} alt="LaScala" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-gvascensori.png" height={80} alt="GV Ascensori" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-farsley.png"
                         height={80} alt="Farsley Transport LTD" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-camparigroup.png" height={80} alt="Campari Group" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-ischebeck-titan.png"
                         height={80} alt="Ischebeck Titan" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-afinnaone.png" height={80} alt="Afinna One" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-denora.png" height={80} alt="De Nora" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-arago.png" height={80} alt="Laboratorio Aragó" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-briggs.png" height={80} alt="Briggs Equipment" />
        </div>
        <div
        >
            <StaticImage src="../../images/customers/aida-customer-arval.png" height={80} alt="Arval" />
        </div>
        <div>
            <StaticImage src="../../images/customers/aida-customer-unifarma.png" height={80} alt="Unifarma" />
        </div>
        <div
        >
            <StaticImage src="../../images/customers/aida-customer-crai.png" height={80} alt="Codè-Crai" />
        </div>
        <div
        >
            <StaticImage src="../../images/customers/aida-customer-fratelli-beretta.png"
                         height={80} alt="Fratelli Beretta" />
        </div>
        <div
        >
            <StaticImage src="../../images/customers/aida-customer-hitachi.png" height={80} alt="Hitachi Rail" />
        </div>
        <div
        >
            <StaticImage src="../../images/customers/aida-customer-morson.png" height={80} alt="Morson Project" />
        </div>
    </Slider>;
};

export default LogoCarousel;

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/gatsby';
import axios from 'axios';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import { useTranslateFormErrors } from '../../shared/hooks';
import {
    getCountries,
    LAMBDA_FORM_HANDLER,
    RECAPTCHA_SITE_KEY,
    registerActiveCampaign,
    registerCapterra,
    registerFacebookEvent,
    registerFormConversion,
    registerFormInteractionConversion,
    registerLinkedinConversion
} from '../../shared/utils';
import Alert from '../UI/Alert/Alert';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import RecaptchaProtected from '../UI/RecaptchaProtected/RecaptchaProtected';

import * as classes from './ContactForm.module.scss';

let formValues = {};

const ContactForm = props => {
    const { isLandingPage = false, onSuccess = null } = props;
    const [ showSuccess, setShowSuccess ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ sending, setSending ] = useState(false);
    const [ formInteracted, setFormInteracted ] = useState(false);
    const partialCompletionFired = useRef(false);
    const { language, t } = useI18next();

    const username = 'NA';

    const recaptchaRef = useRef();
    useTranslateFormErrors();

    const handleFirstInteraction = () => {
        if (!formInteracted) {
            setFormInteracted(true);
            // Only register conversion on first interaction
            if (window.location.host === 'www.aidacloud.com') {
                registerFormInteractionConversion(3);

                posthog.capture('form_interaction', {
                    form: 'contact',
                    action: 'first_field_click'
                });
            }
        }
    };

    const formik = useFormik({
        initialValues: {
            fullName: process.env.NODE_ENV === 'development' ? 'Fulvio Test' : '',
            company: process.env.NODE_ENV === 'development' ? 'Fulvio Azienda test' : '',
            email: process.env.NODE_ENV === 'development' ? 'fulvio.molinengo@aidacloud.com' : '',
            phone: process.env.NODE_ENV === 'development' ? '000111222' : '',
            country: process.env.NODE_ENV === 'development' ? 'Italy' : '',
            message: process.env.NODE_ENV === 'development' ? 'Il mio messaggio 12345' : '',
        },
        validationSchema: Yup.object({
            fullName: Yup.string()
                .min(2)
                .required(),
            company: Yup.string().min(3).required(),
            email: Yup.string().email().required(),
            phone: Yup.string().min(7).required(),
            country: Yup.string().required(),
            message: Yup.string().min(10).required()
        }),
        onSubmit: _values => {
            console.log('Submitted', _values);
            formValues = _values;
            setSending(true);
            setShowError(false);

            console.log('Executing recaptcha');
            recaptchaRef.current.execute();
        }
    });

    // Track partial form completion
    useEffect(() => {
        // Skip if we already fired this conversion
        if (partialCompletionFired.current) return;

        // Count non-empty fields
        const filledFieldsCount = Object.values(formik.values)
            .filter(value => value && value.trim() !== '').length;

        // If at least 2 fields are filled
        if (filledFieldsCount >= 2) {
            // Your conversion tracking function
            const handlePartialConversion = (value) => {
                console.log(`Partial form completion conversion registered with value: ${value}`);

                posthog.capture('form_interaction', {
                    form: 'contact',
                    action: 'partial_form_completion',
                    fields_completed: filledFieldsCount
                });

                registerFormInteractionConversion(7);
            };

            // Register the conversion
            handlePartialConversion(7);

            // Mark as fired so we don't trigger again
            partialCompletionFired.current = true;
        }
    }, [ formik.values ]);

    const handleResolveRecaptcha = captcha => {
        console.log('Recaptcha OK');

        let referrer = document.referrer || 'NA';
        let query = 'NA';

        try {
            const savedReferrer = window.localStorage.getItem('referrer');
            if (!!savedReferrer) {
                referrer = savedReferrer;
                window.localStorage.removeItem('referrer');
            }

            const savedQuery = window.localStorage.getItem('query');
            if (!!savedQuery) {
                query = savedQuery;
                window.localStorage.removeItem('query');
            }
        }
        catch (e) {
            console.error(e);
        }

        axios.post(`${LAMBDA_FORM_HANDLER}/contact`, {
            captcha,
            values: formValues,
            language,
            username,
            referrer,
            query,
            form: 'contact'
        })
            .then(() => {
                setName(formValues.fullName);
                setEmail(formValues.email);
                if (!!onSuccess) {
                    onSuccess(formValues.fullName, formValues.email);
                }
                else {
                    setShowSuccess(true);
                }

                if (!isLandingPage) {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                }

                if (window.location.host === 'www.aidacloud.com') {
                    // Conversion events
                    registerFormConversion(20);
                    registerLinkedinConversion();

                    posthog.identify(formValues.email, {
                        email: formValues.email
                    });

                    posthog.capture('form_submit', {
                        form: 'contact'
                    });

                    registerCapterra();
                    registerActiveCampaign('contact-request', formValues.email);
                    registerFacebookEvent('Contact', 5);
                }
            })
            .catch(err => {
                window.grecaptcha.reset();
                setShowError(true);
                Sentry.captureException(err);
            })
            .finally(() => {
                setSending(false);
            });
    };

    return <>
        {!showSuccess && <div className={clsx(classes.ContactForm, isLandingPage && classes.HideHeader)}>
            {!isLandingPage && <div className={classes.Title}>
                <Trans>If you need any information please fill the form below and you will be contacted by our staff as
                       soon as possible!</Trans>
            </div>}
            <form onSubmit={formik.handleSubmit}>
                <Input
                    id="fullName"
                    label={<>{t('common:contacts.form.fullName.label')}<span className="required">*</span></>}
                    elementConfig={{
                        placeholder: t('common:contacts.form.fullName.placeholder')
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.fullName}
                    inline={true}
                    errors={formik.errors.fullName}
                    invalid={!!formik.errors.fullName}
                    shouldValidate={true}
                    touched={true}
                    onClick={handleFirstInteraction}
                />
                <Input
                    id="company"
                    label={<>{t('common:contacts.form.company.label')}<span className="required">*</span></>}
                    elementConfig={{
                        placeholder: t('common:contacts.form.company.placeholder')
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.company}
                    inline={true}
                    errors={formik.errors.company}
                    invalid={!!formik.errors.company}
                    shouldValidate={true}
                    touched={true}
                    onClick={handleFirstInteraction}
                />
                <Input
                    id="email"
                    label={<>{t('common:contacts.form.email.label')}<span className="required">*</span></>}
                    elementConfig={{
                        placeholder: t('common:contacts.form.email.placeholder')
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    inline={true}
                    errors={formik.errors.email}
                    invalid={!!formik.errors.email}
                    shouldValidate={true}
                    touched={true}
                    onClick={handleFirstInteraction}
                />
                <Input
                    id="phone"
                    label={<>{t('common:contacts.form.phone.label')}<span className="required">*</span></>}
                    elementConfig={{
                        placeholder: t('common:contacts.form.phone.placeholder')
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                    inline={true}
                    errors={formik.errors.phone}
                    invalid={!!formik.errors.phone}
                    shouldValidate={true}
                    touched={true}
                    onClick={handleFirstInteraction}
                />
                <Input
                    id="country"
                    label={<>{t('common:contacts.form.country.label')}<span className="required">*</span></>}
                    onChange={formik.handleChange}
                    value={formik.values.country}
                    inline={true}
                    elementType="select"
                    options={getCountries().map(e => {
                        return {
                            value: e.displayValue,
                            displayValue: e.displayValue
                        };
                    })}
                    addEmptyOption={true}
                    errors={formik.errors.country}
                    invalid={!!formik.errors.country}
                    shouldValidate={true}
                    touched={true}
                    onClick={handleFirstInteraction}
                />
                <Input
                    id="message"
                    label={<>{t('common:contacts.form.message.label')}<span className="required">*</span></>}
                    onChange={formik.handleChange}
                    value={formik.values.message}
                    inline={true}
                    elementType="textarea"
                    elementConfig={{
                        placeholder: t('common:contacts.form.message.placeholder'),
                        rows: 6,
                        style: {
                            style: { 'resize': 'none' }
                        }
                    }}
                    errors={formik.errors.message}
                    invalid={!!formik.errors.message}
                    shouldValidate={true}
                    touched={true}
                    labelConfig={{ style: { whiteSpace: 'normal' } }}
                    onClick={handleFirstInteraction}
                />

                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={handleResolveRecaptcha}
                    size="invisible"
                />

                <div className={classes.Submit}>
                    <Button
                        btnType="Primary"
                        icon={[ 'fad', 'paper-plane' ]}
                        isSubmit={true}
                        loading={sending}
                    >
                        {t('common:contacts.form.button')}
                    </Button>
                    <RecaptchaProtected />
                </div>
            </form>
            {showError &&
                <div style={{ marginTop: '20px' }}><Alert type="Danger" title={t('common:contacts.form.error.title')}>
                    <Trans i18nKey="common:contacts.form.error.message">
                        <p>We're sorry, but an error occurred while submitting your request.</p>
                        <p>You can try again later, or email info@aidacloud.com.</p>
                        <p>We will do our best to respond to your inquiry as soon as possible.</p>
                    </Trans>
                </Alert></div>}
        </div>}
        {showSuccess && <>
            <Alert type="Success" title={t('common:contacts.form.success.title', { name: name })}>
            <Trans i18nKey="common:contacts.form.success.message" email={email}>
                <p>Your request will be reviewed shortly by a person from our team.</p>
                <p>Check your email {{ email }} and look for an email from support@aidacloud.com; if you don't see it
                   within a
                   few minutes, check your "junk mail" or "spam" folder.</p>
                <p>We will do our best to respond to your inquiry as soon as possible.</p>
            </Trans>
            </Alert>
            {isLandingPage &&
                <div className="flex justify-center"><Link to="/" className="btn btn-primary btn-wide mt-4"
                                                           style={{ maxWidth: '300px' }}>
                    <Trans>Explore the features</Trans>&nbsp;
                    <FontAwesomeIcon icon="arrow-right" />
                </Link></div>}
        </>}
    </>;
};

ContactForm.propTypes = {
    isLandingPage: PropTypes.bool,
    onSuccess: PropTypes.func
};

export default ContactForm;

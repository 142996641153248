// extracted by mini-css-extract-plugin
export var Awards = "landing1-module--Awards--509bb";
export var Button = "landing1-module--Button--2f3f0";
export var ContactContainer = "landing1-module--ContactContainer--2a66f";
export var Content = "landing1-module--Content--034b6";
export var CtaButton = "landing1-module--CtaButton--76b0e";
export var CustomersContainer = "landing1-module--CustomersContainer--9aa9d";
export var Description = "landing1-module--Description--c61b1";
export var Detail = "landing1-module--Detail--8334a";
export var Feedback = "landing1-module--Feedback--d2df5";
export var Hero = "landing1-module--Hero--faf6a";
export var Icon = "landing1-module--Icon--b5f0e";
export var Icons = "landing1-module--Icons--e9e29";
export var Image = "landing1-module--Image--9b6c7";
export var Landing = "landing1-module--Landing--8ac8b";
export var Last = "landing1-module--Last--06845";
export var Left = "landing1-module--Left--52d05";
export var Logo = "landing1-module--Logo--27b8a";
export var Right = "landing1-module--Right--31bb4";
export var Section1 = "landing1-module--Section1--15634";
export var Slide = "landing1-module--Slide--c2efa";
export var Stars = "landing1-module--Stars--bc190";
export var Statistic = "landing1-module--Statistic--4b53f";
export var Stats = "landing1-module--Stats--55636";
export var Subtitle = "landing1-module--Subtitle--522d0";
export var Success = "landing1-module--Success--e175f";
export var Tile = "landing1-module--Tile--c50e0";
export var Tile1 = "landing1-module--Tile1--f987e";
export var Tile2 = "landing1-module--Tile2--b7074";
export var Tile3 = "landing1-module--Tile3--72ec2";
export var Tile4 = "landing1-module--Tile4--91112";
export var Tiles = "landing1-module--Tiles--d579f";
export var Title = "landing1-module--Title--8ffd9";
export var floatUpDown = "landing1-module--floatUpDown--f5f63";